import Backend from 'backend';
import {inject, observable} from 'aurelia-framework';
import {FeatureCustomizationProvider} from "../util/feature-customization-provider";
import {Router} from "aurelia-router";
import {ArraySortableByFieldName} from "../util/array-sortable-by-field-name";

@inject(Backend, Router, FeatureCustomizationProvider)
export class ArticleIndexScreen {
  backend;
  router;
  featureCustomizationProvider;
  articles = [];

  @observable({changeHandler: 'changeShowInactiveArticles'})
  showInactiveArticles = false;

  constructor(backend, router, featureCustomizationProvider) {
    this.backend = backend;
    this.router = router;
    this.featureCustomizationProvider = featureCustomizationProvider;
  }

  activate() {
    return this.refresh();
  }

  refresh() {
    return this.backend.ArticleCrud_index(this.showInactiveArticles)
      .then(result => this.articles = new ArraySortableByFieldName(...result));
  }

  changeShowInactiveArticles(newValue, oldValue) {
    // Don't do anything when value is initially bound to checkbox
    if (oldValue === undefined) {
      return;
    }
    this.refresh();
  }

  checkAll() {
    this.allChecked = !this.allChecked;
    this.articles.forEach(article => article.checked = this.allChecked);
  }

  get anyChecked() {
    return this.articles.some(a => a.checked);
  }

  setActiveStatusForAllChecked(active) {
    Promise.all(
      this.articles
        .filter(article => article.checked)
        .map(article => this.backend.SetArticleActiveStatusCommandHandler_handle({
          articleId: article.articleId,
          active: active
        })))
      .then(() => this.refresh());
  }
}
