import Backend from 'backend';
import {inject, observable} from 'aurelia-framework';
import {Router} from "aurelia-router";
import {ArraySortableByFieldName} from "../util/array-sortable-by-field-name";

@inject(Backend, Router)
export class BatchIndexScreen {
  backend;
  router;
  batches = [];

  @observable({changeHandler: 'changeShowInactiveBatches'})
  showInactiveBatches = false;

  constructor(backend, router) {
    this.backend = backend;
    this.router = router;
  }

  activate() {
    return this.refresh();
  }

  refresh() {
    return this.backend.BatchCrud_index(this.showInactiveBatches)
      .then(result => this.batches = new ArraySortableByFieldName(...result));
  }

  changeShowInactiveBatches(newValue, oldValue) {
    // Don't do anything when value is initially bound to checkbox
    if (oldValue === undefined) {
      return;
    }
    this.refresh();
  }

  checkAll() {
    this.allChecked = !this.allChecked;
    this.batches.forEach(batch => batch.checked = this.allChecked);
  }

  get anyChecked() {
    return this.batches.some(b => b.checked);
  }

  setActiveStatusForAllChecked(active) {
    return Promise.all(
      this.batches
        .filter(batch => batch.checked)
        .map(batch => this.backend.SetBatchActiveStatusCommandHandler_handle({
          batchId: batch.batchId,
          active: active
        })))
      .then(() => this.refresh());
  }
}
