const SORT_ORDER = Object.freeze({
  ASC:      "ASC",
  DESC:     "DESC",
  ORIGINAL: "ORIGINAL"
});

export class ArraySortableByFieldName extends Array {
  fieldName;
  sortOrder;

  sortBy(fieldName) {
    // Backup original order so we can restore it when cancelling sort.
    if (!this.original) {
      // Shallow clone for later
      this.original = [...this];
    }

    // If multiple clicks on same column
    if (this.fieldName === fieldName) {
      // Rotate between [null, true, false]
      switch (this.sortOrder) {
        case SORT_ORDER.ORIGINAL:
          this.sortOrder = SORT_ORDER.ASC;
          break;
        case SORT_ORDER.ASC:
          this.sortOrder = SORT_ORDER.DESC;
          break;
        case SORT_ORDER.DESC:
          this.sortOrder = SORT_ORDER.ORIGINAL;
          break;
        default:
          throw new Error(`Unsupported sort order [${this.sortOrder}].`);
      }
    } else {
      // Did not click same as before.
      this.fieldName = fieldName;
      this.sortOrder = SORT_ORDER.ASC;
    }

    // Effectuate sort
    if (this.sortOrder === SORT_ORDER.ORIGINAL) {
      // Restore original sort order
      this.splice(0, this.original.length, ...this.original);
    } else {
      // Sort according to fieldName and sortAsc
      this.sort((a, b) => ArraySortableByFieldName.extracted(a, b, fieldName, this.sortOrder));
    }
  }

  static extracted(aObject, bObject, fieldName, sortOrder) {
    let a = aObject[fieldName];
    let b = bObject[fieldName];

    // Sort
    let sign;
    if (a === b) {
      sign = 0;
    } else if (a === null) {
      sign = -1;
    } else if (b === null) {
      sign = 1;
    } else {
      sign = a.toString().localeCompare(b, undefined, {numeric: true, sensitivity: 'base'});
    }

    // Asc vs desc
    switch (sortOrder) {
      case SORT_ORDER.ASC:
        return sign;
      case SORT_ORDER.DESC:
        return sign * -1;
      case SORT_ORDER.ORIGINAL:
      default:
        throw new Error(`Unsupported sort order [${sortOrder}].`);
    }
  }
}
